// App.tsx
import React from 'react';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from './components/navbar';
import Home from './pages/home';
import ContactUs from './pages/contact';
import './App.css';

import CubicSmash from './pages/cubic-smash'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"        element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/cubic-smash" element={<CubicSmash />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
