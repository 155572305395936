// ContactUs.tsx
import React from 'react';
import './contact.css';
import Navbar from '../components/navbar';
import ContactForm from '../components/contact-us'

const ContactUs: React.FC = () => {
  return (
    <div className="contact-us-container">
      {/* Navigation Bar */}
      <Navbar title="Refuge Studios" />
      <h1>Contact Us</h1>

      <ContactForm/>

    </div>
  );
};

export default ContactUs;
