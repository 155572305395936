// ContactUs.tsx
import React from 'react';
import './contact-us.css';


const ContactUs: React.FC = () => {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>

      <form className="contact-form">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" />

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" rows={4}></textarea>

        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default ContactUs;
